import {active} from "./const.js";

window.addEventListener('scroll', function() {
  let scrollPosition = window.scrollY || document.documentElement.scrollTop;
  const targetElement = document.getElementById('js-toTop');
  if(targetElement) {
      if(scrollPosition >= 200) {
        targetElement.classList.add(active);
      } else {
        targetElement.classList.remove(active);
      }
  }
});
